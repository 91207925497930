import { formatNumber as libFormatPhone } from 'libphonenumber-js';
import Flatpickr from 'flatpickr';
import pluralizeFunction from 'pluralize';
import has from 'lodash/has';
import isObject from 'lodash/isObject';
import lowerCase from 'lodash/lowerCase';
import round from 'lodash/round';
import upperFirst from 'lodash/upperFirst';
import { watch } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import { useRootStore } from '@/user/stores/root';
import { MyCompanyQuery } from '@/user/queries/user.query.graphql';
import { v4 as uuid4 } from 'uuid';
import Cookies from 'js-cookie';

export const convert = {
    // this value is also in ProcessEncirclePdfHandler
    _lbsToKg: 0.45359237,

    lbsToKg: function (lbs) {
        return lbs * convert._lbsToKg;
    },
    kgToLbs: function (kg) {
        return round(kg / convert._lbsToKg, 4);
    },
};

export const convertMaterialWeightForServer = (tripMaterial) => {
    if ('KG' === tripMaterial.materialWeightEnteredUnit) {
        return +tripMaterial.materialWeight;
    }

    return convert.lbsToKg(+tripMaterial.materialWeight);
};
export const convertMaterialWeightForEdit = (tripMaterial) => {
    if ('KG' === tripMaterial.materialWeightEnteredUnit) {
        return tripMaterial.materialWeight;
    }

    return convert.kgToLbs(tripMaterial.materialWeight);
};

export const logError = function (e) {
    if (console && e !== undefined) {
        // eslint-disable-next-line no-console
        console.error(e);
    }
};

export const hasGraphQlError = function (e) {
    return e && e.graphQLErrors && e.graphQLErrors[0];
};

export const formatPhone = function (phone, format = 'NATIONAL') {
    // empty phone number or not an object with the required key
    if (!phone || !phone.phoneNumber) {
        return phone;
    }

    let str = phone.phoneNumber;
    if (phone.extension) {
        str += ' x'+phone.extension;
    }

    return libFormatPhone(str, 'CA', format);
};

export const date = function (date, format = 'M j, Y') {
    if (null === date) {
        return null;
    }

    return Flatpickr.formatDate(Flatpickr.parseDate(date, 'Y-m-d'), format);
};

export const money = function (value, decimals = 2) {
    const price = parseFloat(value / 100);

    // from what I'm reading, Android doesn't fully support the locales and options
    try {
        return price.toLocaleString('en-CA', {
            style: 'currency',
            currency: 'CAD',
            maximumFractionDigits: decimals,
        });
    } catch (e) {
        return '$'+price.toFixed(decimals);
    }
};

export const pluralize = function (string, count) {
    return pluralizeFunction(string, count);
};

export const upperFirstFilter = function (string) {
    return upperFirst(lowerCase(string));
};

export const hasVuelidateProp = function (v, key) {
    return has(v, key);
}

export const vuelidateValue = function (v, key) {
    if (!hasVuelidateProp(v, key)) {
        return true;
    }

    return !v[key].$invalid;
};

export const omitTypename = function (obj) {
    for (const key in obj) {
        if (isObject(obj[key])) {
            omitTypename(obj[key]);
        } else if ('__typename' === key) {
            delete obj[key];
        }
    }

    return obj;
};

export const companyRoles = function (projectCompanies, excludeRole = null) {
    return projectCompanies.reduce((roles, projectCompany) => {
        if (excludeRole && projectCompany.projectRole === excludeRole) {
            return roles;
        }

        if (!roles[projectCompany.projectRole]) {
            roles[projectCompany.projectRole] = [];
        }

        roles[projectCompany.projectRole].push(projectCompany.companyId);

        return roles;
    }, {});
};

export const addEditedWatcher = function (state, edited, variable) {
    watch(
        variable,
        () => {
            if (state.value.matches('ready')) {
                edited.value = true;
            }
        },
        { deep: true, flush: 'sync' },
    );
};

export const switchCurrentCompany = function (newCompanyId) {
    return new Promise((resolve) => {
        const rootStore = useRootStore();

        if ('ALL' === newCompanyId) {
            rootStore.setCurrentCompany('ALL');

            resolve();

            return;
        }

        // load & set the company
        const { onResult, onError } = useQuery(MyCompanyQuery, { companyId: newCompanyId });
        onResult(({ data: { MyCompany } }) => {
            rootStore.setCurrentCompany(MyCompany);

            resolve();
        });
        onError(() => {
            alert('There was a problem changing the company.');
            window.location.reload();
        });
    });
};

export const prepareAdjusters = function (adjusters) {
    return adjusters
        // don't save adjusters without a name, email, or phone number
        .filter((a) => a.name || a.email || a.phoneNumber)
        .map((a) => ({
            ...a,
            projectCompanyAdjusterId: uuid4(),
        }))
};

export const currentCompanyCookie = {
    name: 'current-company-id',

    set (company) {
        const cookieConfig = { expires: 365, secure: true, sameSite: 'strict' };

        if (company === 'ALL') {
            Cookies.remove(this.name, cookieConfig);

            return;
        }

        Cookies.set(this.name, company.companyId, cookieConfig);
    },

    get () {
        return Cookies.get(this.name);
    },
};

export const tripHasFilesOrNotes = (trip) => {
    return trip.tripMaterials.some(tripMaterial => tripMaterial.files.length > 0) || trip.notes.length > 0;
};
